$(document).ready(function () {
    $(".reviews-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        prevArrow: '<div class="slider__arrow slider__arrow-prev"></div>',
        nextArrow: '<div class="slider__arrow slider__arrow-next"></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    });

    $(".questions__item").click(function () {
        $this = $(this);

        if ($this.hasClass("questions__item_opened")) {
            $this.removeClass("questions__item_opened");
            $this.find(".questions__item-body").slideUp();
        } else {
            $this.addClass("questions__item_opened");
            $this.find(".questions__item-body").slideDown();
        }
    });

    $("body").on("click", ".open-popup", function (e) {
        e.preventDefault();

        var el = $(this).data("popup");
        var $this = $(this);

        $.fancybox.open($(".popup-" + el), {
            touch: false,
            helpers: {
                thumbs: {
                    width: 50,
                    height: 50
                },
                overlay: {
                    locked: true
                }
            },
            beforeShow: function () {},
            afterShow: function () {
                var $thisPopup  = ".popup-" + el,
                    sliderClass = ".popup-" + el + " .product-slider",
                    navsClass   = ".popup-" + el + " .product-navs";

                $(sliderClass).slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    fade: true,
                    infinite: false,
                    asNavFor: navsClass
                });
                $(navsClass).slick({
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    asNavFor: sliderClass,
                    dots: false,
                    arrows: false,
                    infinite: false,
                    focusOnSelect: true
                });
            }
        });
    });

    function getFormObject(formArray) {
        var obj = {};
        $.each(formArray, function (i, pair) {
            var cObj = obj, pObj, cpName;
            $.each(pair.name.split("."), function (i, pName) {
                pObj = cObj;
                cpName = pName;
                cObj = cObj[pName] ? cObj[pName] : (cObj[pName] = {});
            });
            pObj[cpName] = pair.value;
        });
        return obj;
    }

    function submitForm(form) {
        var formData = getFormObject($(form).serializeArray());

        axios({
            method: "post",
            url: "./sender.php",
            data: formData,
            config: {headers: {"Content-Type": "application/x-www-form-urlencoded"}}
        }).then(function (response) {
            window.location.replace("./thanks.html");
        }).catch(function (response) {});
    }

    // Формы
    $("form").submit(function () {
        return false;
    });

    $("form").each(function () {
        $(this).validate({
            rules: {
                name: {
                    required: true,
                },
                phone: {
                    required: true
                },
                size: {
                    required: true
                }
            },
            submitHandler: function (form) {
                submitForm(form);
            }
        });
    });

    $(".masked").mask("+7(999)999-99-99");

    $(".scroll-to").click(function () {
        var to = $(this).data("to") || $(this).attr("href");

        if ($(to).length > 0) {
            $.fancybox.close();

            if (event) event.preventDefault();

            var top = $(to).offset().top + 1;

            top = top - $(".header").innerHeight();

            if (to == "#promo") {
                top = 0;
            }

            if($(document).innerWidth() < 1024) {
                $(".header-menu").hide();
                to += $(".header-menu").innerHeight();
            }


            $(".header__open-menu").removeClass("header__open-menu_opened");
            $(".header").removeClass("header_theme_white");

            $("body, html").animate({scrollTop: top}, 500);
        }
    });

    var menu_selector = ".header-menu__list";
    function onScroll() {
        var scroll_top = $(document).scrollTop();

        var $header = $(".header");

        if (scroll_top > $(".promo").innerHeight() - $(".header").innerHeight() - 100) {
            if (!$header.hasClass("header_fixed")) {
                $header.hide().fadeIn().addClass("header_fixed");
            }
        } else {
            $header.removeClass("header_fixed");
        }

        $(menu_selector + " a").each(function () {
            var hash = $(this).attr("href");
            var target = $(hash);
            if (target.length) {
                if (target.position().top - $(".header").innerHeight() <= scroll_top && target.position().top + target.outerHeight() > scroll_top) {
                    $(menu_selector + " .header-menu__list-link").removeClass("header-menu__list-link_active");
                    $(this).addClass("header-menu__list-link_active");
                } else {
                    $(this).removeClass("header-menu__list-link_active");
                }
            }
        });
    }

    onScroll();

    $(document).on("scroll", onScroll);

    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy"
    });

    $(".header__open-menu").click(function () {
        var $this = $(this);

        if($this.hasClass("header__open-menu_opened")) {
            $this.removeClass("header__open-menu_opened");
            $(".header-menu").fadeOut();

            $(".header").removeClass("header_theme_white");
        } else {
            $this.addClass("header__open-menu_opened");
            $(".header-menu").fadeIn();

            $(".header").addClass("header_theme_white");
        }
    });

    $(document).click(function(event) {
        if($(document).innerWidth() < 1024) {
            if ($(event.target).closest(".header").length) return;

            $(".header__open-menu").removeClass("header__open-menu_opened");
            $(".header-menu").fadeOut();
            $(".header").removeClass("header_theme_white");

            event.stopPropagation();
        }
    });

    $(".catalog-tabs__tab").click(function () {
        var $id = $(this).data("id");

        $(".catalog-tabs__tab").removeClass("catalog-tabs__tab_active");
        $(this).addClass("catalog-tabs__tab_active");

        $(".catalog-items").removeClass("catalog-items_active").hide();
        $(".catalog-items[data-id=" + $id + "]").addClass("catalog-items_active").fadeIn();
    });

    $(".catalog-items[data-id=1]").addClass("catalog-items_active").show();

    $(".catalog-item").click(function () {
        var title = $(this).find(".catalog-item__title").text();
        $(".popup-product").find("input[name=formName]").val(title);
    });

    $(".catalog__select").change(function () {
        var id = $(this).val();

        $(".catalog-items").removeClass("catalog-items_active").hide();
        $(".catalog-items[data-id=" + id + "]").addClass("catalog-items_active").fadeIn();
    });
});
